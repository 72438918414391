import { Box, Button, Grid, IconButton, Stack, styled } from "@mui/material";
import ConfirmationModel from "components/ConfirmationModel";
import Status from "components/Status";
import TitleHeader from "components/TitleHeader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteClientNutritionProgram,
  getClientNutritionPrograms,
} from "store/actions/clients";
import { formatDate } from "utils/common";

const StyledBox = styled(Box)(() => ({
  border: "1px solid #E0E4EA",
  width: "100%",
  padding: "12px 16px",
  alignItems: "center",
  color: "#4F5668",
  fontSize: "14px",
  borderRadius: "8px",
  marginBottom: "8px",
  backgroundColor: "#ffffff",
  "&:last-child": {
    marginBottom: "0px",
  },
}));
const NoProgramBox = styled(Box)(({ theme }) => ({
  border: "1px solid #E0E4EA",
  borderRadius: "8px",
  padding: "10px",
  textAlign: "center",
  fontSize: "14px",
  color: "#4F5668",
}));
const ClientNutritionPrograms = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const clientNutritionPrograms = useSelector(
    (state) => state.clients.clientNutritionPrograms
  );
  const [activePrograms, setActiveProgram] = useState([]);
  const [pendingPrograms, setPendingProgramns] = useState([]);
  const [completedPrograms, setCompletedPrograms] = useState([]);
  const [showDeleteModel, setShowDeleteModel] = useState();
  const [selectedNutritionProgram, setSelectedNutritionProgram] = useState();

  useEffect(() => {
    if (clientNutritionPrograms.length === 0) {
      dispatch(getClientNutritionPrograms(params?.id));
    }
  }, []);
  useEffect(() => {
    const active = programsFilterByStatus(clientNutritionPrograms, "Actif");
    const pending = programsFilterByStatus(clientNutritionPrograms, "À venir");
    const completed = programsFilterByStatus(
      clientNutritionPrograms,
      "Complété"
    );
    setActiveProgram(active);
    setPendingProgramns(pending);
    setCompletedPrograms(completed);
  }, [clientNutritionPrograms]);

  const programsFilterByStatus = (programs, status) => {
    return programs.filter((item) => item.status === status);
  };
  const toggleDeleteModel = () => {
    setShowDeleteModel(!showDeleteModel);
  };
  return (
    <>
      <Box py={3}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="space-between"
          gap={1}
          mb={4}
        >
          <TitleHeader title="Programmes de nutrition" fs={20} fw={600} />
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              navigate("/nutrition/programs/create");
            }}
          >
            + Créer un nouveau programme
          </Button>
        </Stack>
        <Box>
          <TitleHeader
            title="Programmes actifs"
            fs={16}
            fw={600}
            mb={1}
            color="#4F5668"
          />
          {activePrograms?.length === 0 && (
            <NoProgramBox>Ce client n’a pas de programme actif.</NoProgramBox>
          )}
          {activePrograms?.map((item) => (
            <StyledBox key={item._id} variant="outlined" severity="info">
              <Grid container>
                <Grid
                  item
                  sm={6}
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  {item.name}
                </Grid>

                <Grid
                  item
                  sm={3}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {formatDate(item.start_date || "") +
                    "-" +
                    formatDate(item.end_date || "")}
                </Grid>
                <Grid
                  item
                  sm={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Status type={item.status} label={item.status} />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: "flex", justifyContent: "right" }}
                >
                  <IconButton
                    color="inherit"
                    size="small"
                    onClick={() => navigate(`/nutrition/programs/${item._id}`)}
                  >
                    <Box
                      component="img"
                      src="/assets/logo/detail_icon.svg"
                      alt="Nutrition program details"
                    ></Box>
                  </IconButton>

                  <IconButton
                    color="inherit"
                    size="small"
                    onClick={(e) => {
                      setSelectedNutritionProgram(item);
                      toggleDeleteModel();
                    }}
                  >
                    <Box
                      component="img"
                      src="/assets/logo/trash_icon.svg"
                      alt="Nutrition program delete"
                    ></Box>
                  </IconButton>
                </Grid>
              </Grid>
            </StyledBox>
          ))}
        </Box>
        <Box mt={3}>
          <TitleHeader
            title="Programmes à venir"
            fs={16}
            fw={600}
            mb={1}
            color="#4F5668"
          />
          {pendingPrograms?.length === 0 && (
            <NoProgramBox>
              Ce client n’a pas de programme en attente.
            </NoProgramBox>
          )}
          {pendingPrograms?.map((item) => (
            <StyledBox key={item._id} variant="outlined" severity="info">
              <Grid container>
                <Grid
                  item
                  sm={6}
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  {item.name}
                </Grid>

                <Grid
                  item
                  sm={3}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {formatDate(item.start_date || "") +
                    "-" +
                    formatDate(item.end_date || "")}
                </Grid>
                <Grid
                  item
                  sm={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Status type="INACTIF" label={item.status} />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: "flex", justifyContent: "right" }}
                >
                  <IconButton
                    color="inherit"
                    size="small"
                    onClick={() => navigate(`/nutrition/programs/${item._id}`)}
                  >
                    <Box
                      component="img"
                      src="/assets/logo/detail_icon.svg"
                      alt="Nutrition program details"
                    ></Box>
                  </IconButton>

                  <IconButton
                    color="inherit"
                    size="small"
                    onClick={(e) => {
                      setSelectedNutritionProgram(item);
                      toggleDeleteModel();
                    }}
                  >
                    <Box
                      component="img"
                      src="/assets/logo/trash_icon.svg"
                      alt="Nutrition program delete"
                    ></Box>
                  </IconButton>
                </Grid>
              </Grid>
            </StyledBox>
          ))}
        </Box>
        <Box mt={3}>
          <TitleHeader
            title="Programmes terminés"
            fs={16}
            fw={600}
            mb={1}
            color="#4F5668"
          />
          {completedPrograms?.length === 0 && (
            <NoProgramBox>
              Ce client n’a pas encore de programme complété.
            </NoProgramBox>
          )}
          {completedPrograms?.map((item) => (
            <StyledBox key={item._id} variant="outlined" severity="info">
              <Grid container>
                <Grid
                  item
                  sm={6}
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  {item.name}
                </Grid>

                <Grid
                  item
                  sm={3}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  {formatDate(item.start_date || "") +
                    "-" +
                    formatDate(item.end_date || "")}
                </Grid>
                <Grid
                  item
                  sm={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Status type="Terminé" label={item.status} />
                </Grid>
                <Grid
                  item
                  sm={1}
                  sx={{ display: "flex", justifyContent: "right" }}
                >
                  <IconButton
                    color="inherit"
                    size="small"
                    onClick={() => navigate(`/nutrition/programs/${item._id}`)}
                  >
                    <Box
                      component="img"
                      src="/assets/logo/detail_icon.svg"
                      alt="Nutrition program details"
                    ></Box>
                  </IconButton>

                  <IconButton
                    color="inherit"
                    size="small"
                    onClick={(e) => {
                      setSelectedNutritionProgram(item);
                      toggleDeleteModel();
                    }}
                  >
                    <Box
                      component="img"
                      src="/assets/logo/trash_icon.svg"
                      alt="Nutrition program delete"
                    ></Box>
                  </IconButton>
                </Grid>
              </Grid>
            </StyledBox>
          ))}
        </Box>
      </Box>
      <ConfirmationModel
        isOpen={showDeleteModel}
        title="Supprimer le programme"
        body={
          <Box>
            Vous êtes sur le point de supprimer{" "}
            <strong>{selectedNutritionProgram?.name}</strong>. Cela le
            supprimera définitivement de votre bibliothèque.
          </Box>
        }
        maxWidth="sm"
        handleClose={toggleDeleteModel}
        onConfirm={() => {
          dispatch(
            deleteClientNutritionProgram(
              selectedNutritionProgram?._id,
              toggleDeleteModel
            )
          );
          setSelectedNutritionProgram(null);
        }}
      />
    </>
  );
};
export default ClientNutritionPrograms;
