import styled from "@emotion/styled";
// import TabContext from "@mui/lab/TabContext";
// import TabList from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { makeStyles } from "@mui/styles";
import ConfirmationModel from "components/ConfirmationModel";
import Status from "components/Status";
import TitleHeader from "components/TitleHeader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteClientFitnessProgram,
  getClientFitnessPrograms,
  getSessionByProgramId,
} from "store/actions/clients";
import { formatDate } from "utils/common";

const useStyle = makeStyles(() => ({
  img: {
    height: "40px",
    width: "40px",
    borderRadius: "8px",
  },
  progressbarText: {
    fontWeight: 600,
    color: "#111111",
    fontSize: "12px",
    lineHeight: "16px",
  },
}));
const StyledBox = styled(Box)(() => ({
  cursor: "pointer",
  border: "1px solid #E0E4EA",
  width: "100%",
  padding: "12px 16px",
  alignItems: "center",
  color: "#4F5668",
  fontSize: "14px",
  borderRadius: "8px",
  marginBottom: "8px",
  backgroundColor: "#ffffff",
  "&:last-child": {
    marginBottom: "0px",
  },
  ".box-inner-box": {
    padding: "16px 32px",
    backgroundColor: "#F5F5F5",
    marginLeft: "-16px",
    marginRight: "-16px",
    marginBottom: "-12px",
    marginTop: 15,
    borderRadius: "0 0 8px 8px",
  },
}));

const Header = styled(Grid)(({ theme }) => ({
  color: theme.palette.info.main,
  fontSize: "12px",
  fontWeight: 600,
  fontFamily: "Poppins",
  color: "#757E97",
  display: "flex",
  gap: "10px",
  alignItems: "center",
}));
const NoProgramBox = styled(Box)(({ theme }) => ({
  border: "1px solid #E0E4EA",
  borderRadius: "8px",
  padding: "10px",
  textAlign: "center",
  fontSize: "14px",
  color: "#4F5668",
  backgroundColor: "#ffffff",
}));
const styleAccordion = styled(Accordion)(({ theme }) => ({
  boxShadow: "none",
  padding: "0px",
  ".MuiButtonBase-root": {
    padding: "0px",
  },
}));
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 32,
  width: "50px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E4EAFC",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 32,
    backgroundColor: "#0001FC",
  },
}));
const ClientFitnessProgram = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clientFitnessPrograms = useSelector(
    (state) => state.clients.clientFitnessPrograms
  );
  const [activePrograms, setActiveProgram] = useState([]);
  const [pendingPrograms, setPendingProgramns] = useState([]);
  const [completedPrograms, setCompletedPrograms] = useState([]);
  const [showDeleteModel, setShowDeleteModel] = useState();
  const [selectedFitnessProgram, setSelectedFitnessProgram] = useState();

  const params = useParams();
  useEffect(() => {
    if (clientFitnessPrograms.length === 0) {
      dispatch(getClientFitnessPrograms(params?.id));
    }
  }, []);
  useEffect(() => {
    const active = programsFilterByStatus(clientFitnessPrograms, "Actif");
    const pending = programsFilterByStatus(clientFitnessPrograms, "À venir");
    const completed = programsFilterByStatus(clientFitnessPrograms, "Complété");
    setActiveProgram(active);
    setPendingProgramns(pending);
    setCompletedPrograms(completed);
  }, [clientFitnessPrograms]);

  const programsFilterByStatus = (programs, status) => {
    return programs.filter((item) => item.status === status);
  };

  const toggleDeleteModel = () => {
    setShowDeleteModel(!showDeleteModel);
  };
  const feedbackColor = (feedback) => {
    if (feedback && feedback.toLowerCase() === "difficile") {
      return "#F05063";
    } else if (feedback && feedback.toLowerCase() === "facile") {
      return "#119C83";
    } else if (feedback && feedback.toLowerCase() === "bien") {
      return "#0001FC";
    }
  };

  return (
    <>
      <Box py={3}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="space-between"
          gap={1}
          mb={4}
        >
          <TitleHeader title="Programmes d’entraînements" fs={20} fw={600} />
          <Button
            color="primary"
            variant="contained"
            onClick={(e) => navigate(`/fitness/programs`)}
          >
            + Créer un nouveau programme
          </Button>
        </Stack>
        <TitleHeader
          title="Programmes actifs"
          fs={16}
          fw={600}
          mb={1}
          color="#4F5668"
        />
        {activePrograms?.length === 0 && (
          <NoProgramBox>Ce client n’a pas de programme actif.</NoProgramBox>
        )}
        {activePrograms?.map((item) => (
          <StyledBox key={item._id} variant="outlined" severity="info">
            <Accordion
              sx={{
                boxShadow: "none",
                ".MuiAccordionSummary-root": {
                  padding: "0 0 0 25px",
                  minHeight: "1px",
                  position: "relative",
                  "& .Mui-expanded": {
                    minHeight: "1px",
                  },
                },
                ".MuiAccordionSummary-content": {
                  margin: 0,
                  "&.Mui-expanded": { margin: 0 },
                },
                ".MuiAccordionSummary-expandIconWrapper": {
                  position: "absolute",
                  left: "-5px",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                onClick={() => {
                  if (!item.sessions) {
                    dispatch(getSessionByProgramId(item._id, params.id));
                  }
                }}
              >
                <Grid container>
                  <Grid
                    item
                    sm={4}
                    sx={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    {item.name}
                  </Grid>

                  <Grid
                    item
                    sm={4}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    {formatDate(item.start_date || "") +
                      "-" +
                      formatDate(item.end_date || "")}
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Box sx={{ width: "100%", mr: 1 }}>
                      <BorderLinearProgress
                        variant="determinate"
                        value={item.progress}
                      />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography
                        className={classes.progressbarText}
                        variant="body2"
                      >{`${Math.round(item.progress)}%`}</Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    sm={2}
                    pl={3}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Status type={item.status} label={item.status} />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: "flex", justifyContent: "right" }}
                  >
                    <IconButton
                      color="inherit"
                      size="small"
                      onClick={(e) => navigate(`/fitness/programs/${item._id}`)}
                    >
                      <Box
                        component="img"
                        src="/assets/logo/detail_icon.svg"
                        alt="Edit icon"
                      ></Box>
                    </IconButton>

                    <IconButton
                      color="info.main"
                      size="small"
                      onClick={(e) => {
                        setSelectedFitnessProgram(item);
                        toggleDeleteModel();
                      }}
                    >
                      <Box
                        component="img"
                        src="/assets/logo/trash_icon.svg"
                        alt="Delete icon"
                      ></Box>
                    </IconButton>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails mt={3} className="box-inner-box">
                <Grid container py={1} px={2}>
                  <Header item sm={4}>
                    Titre de séance
                  </Header>

                  <Header item sm={4}>
                    Date de réalisation
                  </Header>
                  <Header item sm={1}>
                    État
                  </Header>
                  <Header item pl={4} sm={2}>
                    Ressenti
                  </Header>
                  <Header item sm={1} sx={{ justifyContent: "right" }}>
                    Action
                  </Header>
                </Grid>
                {(!item.sessions || item.sessions.length === 0) && (
                  <NoProgramBox>
                    Vous n'avez pas encore de sessions.
                  </NoProgramBox>
                )}
                {item.sessions?.map((session) => (
                  <StyledBox
                    variant="outlined"
                    key={session._id}
                    severity="info"
                  >
                    <Grid container>
                      <Grid
                        item
                        sm={4}
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        {session.name}
                      </Grid>
                      <Grid
                        item
                        sm={4}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {formatDate(session.created_on || "-")}
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {session.isCompleted ? (
                          <CheckCircleIcon fontSize="small" color="success" />
                        ) : (
                          <PanoramaFishEyeIcon
                            fontSize="small"
                            color="secondary"
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        sm={2}
                        pl={4}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <TitleHeader
                          title={session.feedback || "-"}
                          color={() => feedbackColor(session.feedback)}
                          fs={14}
                          fw={700}
                        />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: "flex", justifyContent: "right" }}
                      >
                        <Box
                          onClick={() => {
                            navigate(`/fitness/programs/final/${item._id}`);
                          }}
                        >
                          <TitleHeader
                            title="Details"
                            color="#0001FC"
                            fs={14}
                            fw={700}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </StyledBox>
                ))}
              </AccordionDetails>
            </Accordion>
          </StyledBox>
        ))}
        <TitleHeader
          title="Programmes à venir"
          fs={16}
          fw={600}
          mb={1}
          mt={3}
          color="#4F5668"
        />
        {pendingPrograms?.length === 0 && (
          <NoProgramBox>
            Ce client n’a pas de programme en attente.
          </NoProgramBox>
        )}
        {pendingPrograms?.map((item) => (
          <StyledBox key={item._id} variant="outlined" severity="info">
            <Accordion
              sx={{
                boxShadow: "none",
                ".MuiAccordionSummary-root": {
                  padding: "0 0 0 25px",
                  minHeight: "1px",
                  position: "relative",
                  "& .Mui-expanded": {
                    minHeight: "1px",
                  },
                },
                ".MuiAccordionSummary-content": {
                  margin: 0,
                  "&.Mui-expanded": { margin: 0 },
                },
                ".MuiAccordionSummary-expandIconWrapper": {
                  position: "absolute",
                  left: "-5px",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                onClick={() => {
                  if (!item.sessions) {
                    dispatch(getSessionByProgramId(item._id, params.id));
                  }
                }}
              >
                <Grid container>
                  <Grid
                    item
                    sm={4}
                    sx={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    {item.name}
                  </Grid>

                  <Grid
                    item
                    sm={4}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    {formatDate(item.start_date || "") +
                      "-" +
                      formatDate(item.end_date || "")}
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Box sx={{ width: "100%", mr: 1 }}>
                      <BorderLinearProgress
                        variant="determinate"
                        value={item.progress}
                      />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography
                        className={classes.progressbarText}
                        variant="body2"
                      >{`${Math.round(item.progress)}%`}</Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    sm={2}
                    pl={3}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Status type="INACTIF" label={item.status} />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: "flex", justifyContent: "right" }}
                  >
                    <IconButton
                      color="inherit"
                      size="small"
                      onClick={(e) => navigate(`/fitness/programs/${item._id}`)}
                    >
                      <Box
                        component="img"
                        src="/assets/logo/detail_icon.svg"
                        alt="Edit icon"
                      ></Box>
                    </IconButton>

                    <IconButton
                      color="info.main"
                      size="small"
                      onClick={(e) => {
                        setSelectedFitnessProgram(item);
                        toggleDeleteModel();
                      }}
                    >
                      <Box
                        component="img"
                        src="/assets/logo/trash_icon.svg"
                        alt="Delete icon"
                      ></Box>
                    </IconButton>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails mt={3} className="box-inner-box">
                <Grid container py={1} px={2}>
                  <Header item sm={4}>
                    Titre de séance
                  </Header>

                  <Header item sm={4}>
                    Date de réalisation
                  </Header>
                  <Header item sm={1}>
                    État
                  </Header>
                  <Header item pl={4} sm={2}>
                    Ressenti
                  </Header>
                  <Header item sm={1} sx={{ justifyContent: "right" }}>
                    Action
                  </Header>
                </Grid>
                {(!item.sessions || item.sessions.length === 0) && (
                  <NoProgramBox>
                    Vous n'avez pas encore de sessions.
                  </NoProgramBox>
                )}
                {item.sessions?.map((session) => (
                  <StyledBox
                    variant="outlined"
                    key={session._id}
                    severity="info"
                  >
                    <Grid container>
                      <Grid
                        item
                        sm={4}
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        {session.name}
                      </Grid>
                      <Grid
                        item
                        sm={4}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {formatDate(session.created_on || "-")}
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {session.isCompleted ? (
                          <CheckCircleIcon fontSize="small" color="success" />
                        ) : (
                          <PanoramaFishEyeIcon
                            fontSize="small"
                            color="secondary"
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        sm={2}
                        pl={4}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <TitleHeader
                          title="-"
                          color="#0001FC"
                          fs={14}
                          fw={700}
                        />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: "flex", justifyContent: "right" }}
                      >
                        <TitleHeader
                          title="Details"
                          color="#0001FC"
                          fs={14}
                          fw={700}
                          onClick={() =>
                            navigate(`/fitness/programs/final/${item._id}`)
                          }
                        />
                      </Grid>
                    </Grid>
                  </StyledBox>
                ))}
              </AccordionDetails>
            </Accordion>
          </StyledBox>
        ))}
        <TitleHeader
          title="Programmes terminés/inactifs"
          fs={16}
          fw={600}
          mb={1}
          mt={3}
          color="#4F5668"
        />
        {completedPrograms?.length === 0 && (
          <NoProgramBox>
            Ce client n’a pas encore de programme complété.
          </NoProgramBox>
        )}
        {completedPrograms?.map((item) => (
          <StyledBox key={item._id} variant="outlined" severity="info">
            <Accordion
              sx={{
                boxShadow: "none",
                ".MuiAccordionSummary-root": {
                  padding: "0 0 0 25px",
                  minHeight: "1px",
                  position: "relative",
                  "& .Mui-expanded": {
                    minHeight: "1px",
                  },
                },
                ".MuiAccordionSummary-content": {
                  margin: 0,
                  "&.Mui-expanded": { margin: 0 },
                },
                ".MuiAccordionSummary-expandIconWrapper": {
                  position: "absolute",
                  left: "-5px",
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                onClick={() => {
                  if (!item.sessions) {
                    dispatch(getSessionByProgramId(item._id, params.id));
                  }
                }}
              >
                <Grid container>
                  <Grid
                    item
                    sm={4}
                    sx={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    {item.name}
                  </Grid>

                  <Grid
                    item
                    sm={4}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    {formatDate(item.start_date || "") +
                      "-" +
                      formatDate(item.end_date || "")}
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Box sx={{ width: "100%", mr: 1 }}>
                      <BorderLinearProgress
                        variant="determinate"
                        value={item.progress}
                      />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                      <Typography
                        className={classes.progressbarText}
                        variant="body2"
                      >{`${Math.round(item.progress)}%`}</Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    sm={2}
                    pl={3}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Status type="Terminé" label={item.status} />
                  </Grid>
                  <Grid
                    item
                    sm={1}
                    sx={{ display: "flex", justifyContent: "right" }}
                  >
                    <IconButton
                      color="inherit"
                      size="small"
                      onClick={(e) => navigate(`/fitness/programs/${item._id}`)}
                    >
                      <Box
                        component="img"
                        src="/assets/logo/detail_icon.svg"
                        alt="Edit icon"
                      ></Box>
                    </IconButton>

                    <IconButton
                      color="info.main"
                      size="small"
                      onClick={(e) => {
                        setSelectedFitnessProgram(item);
                        toggleDeleteModel();
                      }}
                    >
                      <Box
                        component="img"
                        src="/assets/logo/trash_icon.svg"
                        alt="Delete icon"
                      ></Box>
                    </IconButton>
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails mt={3} className="box-inner-box">
                <Grid container py={1} px={2}>
                  <Header item sm={4}>
                    Titre de séance
                  </Header>

                  <Header item sm={4}>
                    Date de réalisation
                  </Header>
                  <Header item sm={1}>
                    État
                  </Header>
                  <Header item pl={4} sm={2}>
                    Ressenti
                  </Header>
                  <Header item sm={1} sx={{ justifyContent: "right" }}>
                    Action
                  </Header>
                </Grid>
                {(!item.sessions || item.sessions.length === 0) && (
                  <NoProgramBox>
                    Vous n'avez pas encore de sessions.
                  </NoProgramBox>
                )}
                {item.sessions?.map((session) => (
                  <StyledBox
                    variant="outlined"
                    key={session._id}
                    severity="info"
                  >
                    <Grid container>
                      <Grid
                        item
                        sm={4}
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                        }}
                      >
                        {session.name}
                      </Grid>
                      <Grid
                        item
                        sm={4}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {formatDate(session.created_on || "-")}
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        {session.isCompleted ? (
                          <CheckCircleIcon fontSize="small" color="success" />
                        ) : (
                          <PanoramaFishEyeIcon
                            fontSize="small"
                            color="secondary"
                          />
                        )}
                      </Grid>
                      <Grid
                        item
                        sm={2}
                        pl={4}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <TitleHeader
                          title="-"
                          color="#0001FC"
                          fs={14}
                          fw={700}
                        />
                      </Grid>
                      <Grid
                        item
                        sm={1}
                        sx={{ display: "flex", justifyContent: "right" }}
                      >
                        <TitleHeader
                          title="Details"
                          color="#0001FC"
                          fs={14}
                          fw={700}
                          onClick={() =>
                            navigate(`/fitness/programs/final/${item._id}`)
                          }
                        />
                      </Grid>
                    </Grid>
                  </StyledBox>
                ))}
              </AccordionDetails>
            </Accordion>
          </StyledBox>
        ))}
      </Box>
      <ConfirmationModel
        isOpen={showDeleteModel}
        title="Supprimer la session"
        body={
          <Box>
            Vous êtes sur le point de supprimer{" "}
            <strong>{selectedFitnessProgram?.name}</strong>. Cela le supprimera
            définitivement de votre bibliothèque.
          </Box>
        }
        maxWidth="sm"
        handleClose={toggleDeleteModel}
        onConfirm={() => {
          dispatch(
            deleteClientFitnessProgram(
              selectedFitnessProgram?._id,
              toggleDeleteModel
            )
          );
          setSelectedFitnessProgram(null);
        }}
      />
    </>
  );
};

export default ClientFitnessProgram;
