import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CreateNutritionDays from "components/CreateNutritionDays";
import CustomIcon from "components/CustomIcon";
import { FormContext } from "components/FormContext";
import ProgramSaveAsTemplateModal from "components/ProgramSaveAsTemplateModal";
import SendProgramModal from "components/SendProgramModal";
import TitleHeader from "components/TitleHeader";
import { addDays } from "date-fns";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setHeaderDetails } from "store/actions/header";
import {
  addWeek,
  copySessionData,
  duplicateThisWeek,
  getCurrentNutrition,
  pasteSessionData,
  removeWeek,
  setActiveWeekNutrition,
  setNutritionData,
} from "store/actions/nutritions";
import { setPrompt } from "store/actions/prompt";
import { ATTENTION_MESSAGE } from "utils/constants";
import Loader from "../../components/Loader";
import NutritionFinalForm from "./NutritionFinalForm";

const StyledSelect = styled(Select)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 600,
  color: "#4F5668",
  borderRadius: "8px",
  backgroundColor: "#ffffff",
  padding: "1px 12px",
  ".MuiOutlinedInput-notchedOutline": {
    border: "1px solid #E0E4EA",
  },
}));
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 600,
  color: "#4F5668",
}));

const StyledSaveSelect = styled(Select)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 600,
  color: "#119C83",
  borderRadius: "8px",
  verticalAlign: "middle",
  backgroundColor: "#E4F2E5",
  padding: "0px 12px",
  ".MuiOutlinedInput-notchedOutline": {
    border: "1px solid #119C83",
  },
  ".MuiSelect-select": {
    color: "#119C83",
  },
}));
const StyledSaveMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: "14px",
  color: "#111111",
  paddingTop: "10px",
  paddingBottom: "10px",
  "&:hover": {
    backgroundColor: "#E4F2E5",
  },
}));
const NutritionInfo = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { formikRef, handleSubmit } = useContext(FormContext);

  const totalWeek = useSelector((state) => state.nutritions.totalWeek);
  const activeWeek = useSelector((state) => state.nutritions.activeWeek);
  const copiedSession = useSelector((state) => state.nutritions.copiedSession);
  const nutritionData = useSelector((state) => state.nutritions.nutritionData);
  const [showSaveTemplateModal, setShowSaveTemplateModal] = useState(false);
  const [showSendProgramModal, setShowSendProgramModal] = useState(false);
  const isLoadingNutrition = useSelector(
    (state) => state.nutritions.isLoadingNutritins
  );

  useEffect(() => {
    if (id) {
      dispatch(getCurrentNutrition(id, formikRef));
    }
    const navDetail = {
      isBreadcrumns: true,
      title: "",
      breadcrumsList: [
        {
          title: "Programmes de nutrition",
          link: "/nutrition/programs",
          command: () => {
            dispatch(
              setPrompt({
                isOpen: true,
                message: ATTENTION_MESSAGE,
                primaryBtnHandler: () => {
                  navigate("/nutrition/programs");
                },
                btnAlign: "right",
                primaryBtnColor: "error",
                secondaryBtnText: "Annuler",
              })
            );
          },
        },
        {
          title: "Étape 1",
          link: id ? `/nutrition/programs/${id}` : "/nutrition/programs/create",
          command: () => {
            dispatch(
              setPrompt({
                isOpen: true,
                message:
                  "Vous êtes sur le point de retour à l’étape 1 sans enregistrer, ce qui entraînera la perte de vos modifications. Êtes-vous sûr de vouloir continuer cette action ?",
                primaryBtnHandler: () => {
                  navigate(
                    id
                      ? `/nutrition/programs/${id}`
                      : "/nutrition/programs/create"
                  );
                },
                btnAlign: "right",
                primaryBtnColor: "error",
                secondaryBtnText: "Annuler",
              })
            );
          },
        },
        {
          title: "Étape 2",
          link: "",
        },
      ],
    };
    dispatch(setHeaderDetails(navDetail));
  }, []);

  useEffect(() => {
    updateEndDate(totalWeek);
  }, [totalWeek, nutritionData?.start_date]);

  const handlePrevPaginate = () => {
    if (activeWeek !== 0) {
      dispatch(setActiveWeekNutrition(activeWeek - 1));
    }
  };

  const handleNextPaginate = () => {
    if (activeWeek !== totalWeek - 1) {
      dispatch(setActiveWeekNutrition(activeWeek + 1));
    }
  };

  const handleWeekActions = (event) => {
    if (event.target.value === "DUPLICATE_WEEK") {
      dispatch(duplicateThisWeek(formikRef, activeWeek));
    } else if (event.target.value === "ADD_WEEK") {
      dispatch(addWeek(formikRef, activeWeek));
    } else if (event.target.value === "REMOVE_WEEK") {
      dispatch(removeWeek(formikRef, activeWeek));
    }
  };

  const handleNutrationSaveActions = (event) => {
    if (event.target.value === "SAVE_AS_TEMPLATE") {
      setShowSaveTemplateModal(true);
    } else if (event.target.value === "SAVE_AS_DRAFT") {
      formikRef.current?.setFieldValue("status", "Brouillon");
      handleSubmit();
    }
  };

  const toggleModal = (flag) => {
    if (flag === "SAVE_TEMPLATE") {
      setShowSaveTemplateModal(!showSaveTemplateModal);
    } else {
      setShowSendProgramModal(!showSendProgramModal);
    }
  };

  const updateEndDate = (weeks) => {
    const endDate = dayjs(
      addDays(new Date(nutritionData.start_date), weeks * 7)
    );
    dispatch(
      setNutritionData({
        ...nutritionData,
        end_date: endDate,
        program_weeks: weeks,
      })
    );
  };

  const updateStartDate = (date) => {
    dispatch(
      setNutritionData({
        ...nutritionData,
        start_date: date,
      })
    );
  };

  return (
    <>
      <Loader isLoading={isLoadingNutrition} />
      <Box sx={{ display: isLoadingNutrition ? "none" : "" }}>
        <Container
          maxWidth="xxl"
          sx={{ paddingTop: "0px !important" }}
          className="no-bg"
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            justifyContent="space-between"
            gap={1}
          >
            <TitleHeader title={nutritionData?.name} />
            <Box>
              <StyledSaveSelect
                id="save"
                value={""}
                sx={{
                  marginRight: "10px",
                  display:
                    id &&
                    (nutritionData?.status === "Actif" ||
                      nutritionData?.status === "Modèle")
                      ? "none"
                      : "inline-block",
                }}
                displayEmpty
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return "Sauvegarder";
                  }
                }}
                onChange={handleNutrationSaveActions}
              >
                <StyledSaveMenuItem value="SAVE_AS_TEMPLATE">
                  Sauvegarder
                </StyledSaveMenuItem>
                <StyledSaveMenuItem value="SAVE_AS_DRAFT">
                  En tant que brouillon
                </StyledSaveMenuItem>
              </StyledSaveSelect>
              {id && nutritionData?.status === "Modèle" && (
                <Button
                  type="submit"
                  color="success"
                  variant="outlined"
                  sx={{ marginRight: "10px" }}
                  onClick={() => setShowSaveTemplateModal(true)}
                >
                  Sauvegarder
                </Button>
              )}
              <Button
                type="submit"
                color="success"
                variant="contained"
                onClick={() => {
                  if (id && nutritionData?.status === "Actif") {
                    handleSubmit();
                  } else {
                    setShowSendProgramModal(true);
                  }
                }}
              >
                {id && nutritionData?.status === "Actif"
                  ? "Sauvergarder les modifications"
                  : "Envoyer"}
              </Button>
            </Box>
          </Stack>
          <Divider sx={{ margin: "20px 0" }} />
          <Box pb={2}>
            <Stack
              direction="row"
              alignItems="center"
              flexWrap="wrap"
              justifyContent="space-between"
            >
              <Stack direction="row" alignItems="center" gap={2}>
                <IconButton onClick={handlePrevPaginate}>
                  <img src="/assets/logo/previous.svg" alt="previous" />
                </IconButton>
                <Typography
                  fontWeight="600"
                  fontFamily="Poppins"
                  fontSize="20px"
                >
                  Semaine {activeWeek + 1} sur {totalWeek}
                </Typography>
                <IconButton onClick={handleNextPaginate}>
                  <img src="/assets/logo/next.svg" alt="previous" />
                </IconButton>
              </Stack>
              <Stack direction="row" alignItems="center">
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="fr"
                >
                  <DatePicker
                    label="Date de début"
                    onChange={(newValue) => updateStartDate(newValue)}
                    value={nutritionData?.start_date}
                    format="DD MMM YYYY"
                    sx={{
                      backgroundColor: "#ffffff",
                      width: "155px",
                      marginRight: "15px",
                    }}
                  />
                </LocalizationProvider>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="fr"
                >
                  <DatePicker
                    label="Date de fin"
                    disabled
                    format="DD MMM YYYY"
                    minDate={dayjs(nutritionData?.start_date)}
                    sx={{ width: "155px", marginRight: "15px" }}
                    value={nutritionData?.end_date}
                  />
                </LocalizationProvider>
                <StyledSelect
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={""}
                  displayEmpty
                  IconComponent={MoreHorizIcon}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return "Gérer";
                    }
                  }}
                  onChange={handleWeekActions}
                >
                  <StyledMenuItem value="DUPLICATE_WEEK">
                    Dupliquer la semaine
                  </StyledMenuItem>
                  <StyledMenuItem value="ADD_WEEK">
                    Ajouter une semaine
                  </StyledMenuItem>
                  <StyledMenuItem value="REMOVE_WEEK">
                    Supprimer la semaine
                  </StyledMenuItem>
                </StyledSelect>
              </Stack>
            </Stack>
            <CreateNutritionDays />
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              backgroundColor: "#ffffff",
              padding: "16px 32px",
              borderRadius: "8px",
            }}
          >
            <TitleHeader title="Menu du jour" fs={20} fw="600" />
            <Stack direction="row" alignItems="center">
              <IconButton
                color="inherit"
                size="small"
                sx={{ marginRight: "5px" }}
                onClick={() => dispatch(copySessionData(formikRef))}
              >
                <CustomIcon
                  title="Copy Recipe"
                  iconSrc="/assets/logo/copy_big.svg"
                />
              </IconButton>
              {copiedSession && (
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={() => dispatch(pasteSessionData(formikRef))}
                >
                  <CustomIcon
                    title="Past Recipe"
                    iconSrc="/assets/logo/past.svg"
                  />
                </IconButton>
              )}
            </Stack>
          </Stack>

          <Box py={2}>
            <Stack>
              <NutritionFinalForm />
            </Stack>
          </Box>
        </Container>
      </Box>
      <ProgramSaveAsTemplateModal
        showSaveTemplateModal={showSaveTemplateModal}
        setShowSaveTemplateModal={setShowSaveTemplateModal}
        handleSubmit={() => {
          formikRef.current?.setFieldValue("status", "Modèle");
          handleSubmit();
        }}
      />
      <SendProgramModal
        showSendProgramModal={showSendProgramModal}
        setShowSendProgramModal={setShowSendProgramModal}
        handleDraftSubmit={() => {
          formikRef.current?.setFieldValue("status", "Brouillon");
          setShowSendProgramModal(false);
          handleSubmit();
        }}
      />
    </>
  );
};

export default NutritionInfo;
