import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Grid, IconButton, InputAdornment, MenuItem } from "@mui/material";
import Field from "components/Field";
import { required } from "components/Field/validate";
import TitleHeader from "components/TitleHeader";
import { connect } from "formik";
import CardImageUploader from "../../CardImageUploader";

const RecipePreview = ({ formik }) => {
  const { values, setFieldValue } = formik;

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={6} sm={6}>
          <TitleHeader el="h3" mb={3} title="Details" fw={600} fs={16} />
          <Box pr={5} sx={{ borderRight: "1px solid #E0E4EA" }}>
            <Field
              type="text"
              label="Titre de la recette"
              name="name"
              size="small"
              validate={required}
              mb={4}
            />
            <Field
              type="select"
              label="Catégorie"
              placeholder="Select category"
              name="meal_type"
              size="small"
              notched={true}
              mb={4}
            >
              <MenuItem value={`Petit déjeuner`}>Petit déjeuner</MenuItem>
              <MenuItem value={`Déjeuner`}>Déjeuner</MenuItem>
              <MenuItem value={`Dîner`}>Dîner</MenuItem>
              <MenuItem value={`Collation`}>Collation</MenuItem>
            </Field>
            <Box mb={2} mt={3} sx={{ fontSize: "12px", fontWeight: "600" }}>
              Temps
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Field
                  type="number"
                  label="Prep Time"
                  name="prep_time"
                  size="small"
                  validate={required}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        sx={{ ".MuiTypography-body1": { fontSize: "14px" } }}
                        position="end"
                      >
                        min
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Field
                  type="number"
                  label="Cook Time"
                  name="cook_time"
                  size="small"
                  validate={required}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        sx={{ ".MuiTypography-body1": { fontSize: "14px" } }}
                        position="end"
                      >
                        min
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Box mb={2} mt={3} sx={{ fontSize: "12px", fontWeight: "600" }}>
              Détails nutritifs
            </Box>
            <Grid container spacing={2}>
              <Grid item sm={9}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={3}>
                    <Field
                      type="number"
                      label="Kcal"
                      placeholder="0"
                      name="kcal"
                      size="small"
                      validate={required}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            sx={{
                              ".MuiTypography-body1": { fontSize: "14px" },
                            }}
                            position="end"
                          >
                            g
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Field
                      type="number"
                      label="Protéine"
                      placeholder="0"
                      name="protin"
                      size="small"
                      validate={required}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            sx={{
                              ".MuiTypography-body1": { fontSize: "14px" },
                            }}
                            position="end"
                          >
                            g
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Field
                      type="number"
                      label="Lipides"
                      placeholder="0"
                      name="lipides"
                      size="small"
                      validate={required}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            sx={{
                              ".MuiTypography-body1": { fontSize: "14px" },
                            }}
                            position="end"
                          >
                            g
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Field
                      type="number"
                      label="Glucides"
                      placeholder="0"
                      name="glucides"
                      size="small"
                      validate={required}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            sx={{
                              ".MuiTypography-body1": { fontSize: "14px" },
                            }}
                            position="end"
                          >
                            g
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Field
                  type="number"
                  label="Serving Size"
                  placeholder="0"
                  name="serving"
                  size="small"
                  sx={{ ".MuiInputBase-input": { textAlign: "center" } }}
                  validate={required}
                  InputProps={{
                    startAdornment: (
                      <IconButton
                        edge="start"
                        onClick={() =>
                          setFieldValue("serving", values.serving - 1)
                        }
                      >
                        <RemoveIcon />
                      </IconButton>
                    ),
                    endAdornment: (
                      <IconButton
                        edge="end"
                        onClick={() =>
                          setFieldValue("serving", values.serving + 1)
                        }
                      >
                        <AddIcon />
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={6} sm={6}>
          <Box mb={2} sx={{ fontSize: "12px", fontWeight: "600" }}>
            Image de la recette
          </Box>
          <CardImageUploader
            name="thumbnail"
            label="Ajouter une image"
            thumbnail={values ? values.thumbnail : null}
            apiKey="/api/upload/coach/thumbs/exercise/new_thumb"
          />
          <Box mb={2} sx={{ fontSize: "12px", fontWeight: "600" }}>
            Vidéo de la recette
          </Box>

          <CardImageUploader
            name="video"
            isVideo={true}
            label="Ajouter une vidéo"
            apiKey="/api/upload/coach/videos/exercises/new_video"
            imgApiKey="/api/upload/coach/thumbs/exercise/new_thumb"
          />
        </Grid>
      </Grid>
      <Grid container spacing={{ md: 3, xs: 0 }}>
        <Grid item xs={12} md={9}></Grid>
        <Grid item xs={12} md={3}></Grid>
      </Grid>
    </>
  );
};

export default connect(RecipePreview);
