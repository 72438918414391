import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Container,
  InputAdornment,
  Pagination,
  PaginationItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ConfirmationModel from "components/ConfirmationModel";
import FilterMenu from "components/FilterMenu";
import Loader from "components/Loader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setHeaderDetails } from "store/actions/header";
import { cleanAllProgramData, getInitialProgram } from "store/actions/program";
import {
  changePagination,
  deleteSessionById,
  getSessions,
  searchSessions,
} from "store/actions/sessions";
import { programStatuses } from "utils/programs.utils";
import SessionListBody from "./SessionListBody";
import SessionListHeader from "./SessionListHeader";

const SessionList = () => {
  const dispatch = useDispatch();
  const sessions = useSelector((state) => state.sessions.sessions);
  const navigate = useNavigate();
  const isLoadingSessions = useSelector(
    (state) => state.sessions.isLoadingSessions
  );
  const currentPage = useSelector((state) => state.sessions.currentPage);
  const [current, setCurrent] = useState(null);
  const [showDeleteModel, setDeleteModel] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState([]);
  const start = (currentPage - 1) * 10;
  const end = start + 10;
  const currentPageItems = (sessions || []).slice(start, end).length;

  const toggleDeleteModel = (ele = "") => {
    setDeleteModel(!showDeleteModel);
    setCurrent(ele);
  };
  useEffect(() => {
    dispatch(getSessions());
    const navDetail = {
      isBreadcrumns: false,
      title: "Séances",
    };

    dispatch(setHeaderDetails(navDetail));
  }, []);
  const getStatusFilterValues = (values) => {
    setFilters(values);
    dispatch(searchSessions(searchQuery, values));
  };
  return (
    <>
      <Container
        maxWidth="xxl"
        className="header-box"
        sx={{ padding: "0 !important", marginBottom: "20px" }}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="space-between"
          gap={1}
          mt={2}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            justifyContent="space-between"
            gap={1}
          >
            <TextField
              placeholder="Rechercher une séance"
              name="search sessions"
              sx={{
                width: { sm: "100%", md: "414px" },
                ".MuiInputBase-root": {
                  background: "#ffffff",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                dispatch(searchSessions(e.target.value, filters));
              }}
            />
            <FilterMenu
              menuTitle="Statut"
              getSelectedValues={getStatusFilterValues}
              menuList={programStatuses}
            />
          </Stack>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              dispatch(cleanAllProgramData());
              dispatch(getInitialProgram());
              navigate("/fitness/sessions/create");
            }}
          >
            {" "}
            + Créer une séance
          </Button>
        </Stack>
      </Container>
      <Container maxWidth="xxl">
        <Stack gap={1}>
          <Loader isLoading={isLoadingSessions} />
          {!isLoadingSessions && <SessionListHeader />}
          {(sessions || []).length === 0 && !isLoadingSessions && (
            <Box component="h5" textAlign="center">
              Vous n'avez pas encore de sessions.
            </Box>
          )}

          {!isLoadingSessions &&
            (sessions || [])
              .slice(start, end)
              .map((session, index) => (
                <SessionListBody
                  key={index}
                  session={session}
                  toggleDeleteModel={toggleDeleteModel}
                />
              ))}
          {!isLoadingSessions && (sessions || []).length > 10 && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography color="#000000" fontSize="12px" fontWeight="500">
                Affichage de {start + 1} à {start + currentPageItems} sur{" "}
                {sessions.length} résultats
              </Typography>
              <Pagination
                page={currentPage}
                onChange={(page, value) => dispatch(changePagination(value))}
                count={Math.ceil(sessions.length / 10)}
                variant="outlined"
                shape="rounded"
                color="secondary"
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
              />
            </Stack>
          )}
        </Stack>
        <ConfirmationModel
          isOpen={showDeleteModel}
          title="Supprimer la session"
          body={[
            "Vous êtes sur le point de supprimer",
            <strong> {current?.name}</strong>,
            ". Cela le supprimera définitivement de votre bibliothèque.",
          ]}
          handleClose={toggleDeleteModel}
          maxWidth="sm"
          onConfirm={() => {
            dispatch(deleteSessionById(current._id, toggleDeleteModel));
          }}
        />
      </Container>
    </>
  );
};

export default SessionList;
