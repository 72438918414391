import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Container,
  InputAdornment,
  Pagination,
  PaginationItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ConfirmationModel from "components/ConfirmationModel";
import FilterMenu from "components/FilterMenu";
import Loader from "components/Loader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setHeaderDetails } from "store/actions/header";
import {
  changePagination,
  deleteRecipe,
  getRecipeLists,
  searchRecipes,
} from "store/actions/recipes";
import useDocumentTitle from "utils/hooks/useDocumentTitle";
import ExecericeTitles from "./ExecericeTitles";
import ExcerciceItem from "./ExecrciceItem";

const NutritionPage = () => {
  useDocumentTitle("Mes Recettes", true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const recipes = useSelector((state) => state.recipes.recipesLists);
  const isLoadingRecipes = useSelector(
    (state) => state.recipes.isLoadingRecipes
  );
  const currentPage = useSelector((state) => state.recipes.currentPage);
  const [current, setCurrent] = useState(null);
  const [showDeleteModel, setDeleteModel] = useState(false);
  const [showInfoModel, setInfoModel] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState([]);
  const [ownerFilters, setOwnerFilters] = useState([]);

  const toggleDeleteModel = (element = null) => {
    setCurrent(element);
    setDeleteModel(!showDeleteModel);
  };

  const toggleInfoModel = () => {
    setInfoModel(!showInfoModel);
  };

  useEffect(() => {
    dispatch(getRecipeLists());
    const navDetail = {
      isBreadcrumns: false,
      title: "Recettes",
    };

    dispatch(setHeaderDetails(navDetail));
  }, []);

  const start = (currentPage - 1) * 10;
  const end = start + 10;
  const currentPageItems = (recipes || []).slice(start, end).length;

  const filerMenuList = [
    {
      id: 1,
      name: "Petit déjeuner",
    },
    {
      id: 2,
      name: "Déjeuner",
    },
    {
      id: 3,
      name: "Dîner",
    },
    {
      id: 4,
      name: "Collation",
    },
  ];
  const createdByMenuList = [
    {
      id: 1,
      name: "Moi",
    },
    {
      id: 2,
      name: "Evocoach",
    },
  ];
  const getStatusFilterValues = (values) => {
    setFilters(values);
    dispatch(searchRecipes(searchQuery, ownerFilters, values));
  };
  const getOwnerFilterValues = (values) => {
    setOwnerFilters(values);
    dispatch(searchRecipes(searchQuery, values, filters));
  };
  return (
    <>
      <Container
        maxWidth="xxl"
        className="header-box"
        sx={{ padding: "0 !important", marginBottom: "20px" }}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="space-between"
          gap={1}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            justifyContent="space-between"
            gap={1}
          >
            <TextField
              placeholder="Rechercher une recette"
              name="searchRecipes"
              sx={{
                width: { sm: "100%", md: "414px" },
                ".MuiInputBase-root": {
                  background: "#ffffff",
                },
              }}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                dispatch(searchRecipes(e.target.value, filters));
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <FilterMenu
              menuTitle="Créer par"
              getSelectedValues={getOwnerFilterValues}
              menuList={createdByMenuList}
            />
            <FilterMenu
              menuTitle="Catégorie"
              getSelectedValues={getStatusFilterValues}
              menuList={filerMenuList}
            />
          </Stack>
          <Button
            color="primary"
            variant="contained"
            onClick={() => navigate("/nutrition/recipes/create")}
          >
            {" "}
            + Créer une recette
          </Button>
        </Stack>
      </Container>
      <Container maxWidth="xxl">
        <Stack gap={3}>
          <Stack gap={1}>
            <Loader isLoading={isLoadingRecipes} />

            {!isLoadingRecipes && (
              <Box gap={1} sx={{ minHeight: "638px" }}>
                <ExecericeTitles />
                {(recipes || []).length === 0 && !isLoadingRecipes && (
                  <Box component="h5" textAlign="center">
                    Vous n'avez pas encore de recettes.
                  </Box>
                )}
                {(recipes || []).slice(start, end).map((recipe) => (
                  <ExcerciceItem
                    key={recipe["_id"]}
                    recipe={recipe}
                    toggleDeleteModel={toggleDeleteModel}
                    toggleInfoModel={toggleInfoModel}
                  />
                ))}
              </Box>
            )}
          </Stack>

          {(recipes || []).length > 10 && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography color="#000000" fontSize="12px" fontWeight="500">
                Affichage de {start + 1} à {start + currentPageItems} sur{" "}
                {recipes.length} résultats
              </Typography>
              <Pagination
                page={currentPage}
                onChange={(page, value) => dispatch(changePagination(value))}
                count={Math.ceil(recipes.length / 10)}
                variant="outlined"
                shape="rounded"
                color="secondary"
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
              />
            </Stack>
          )}
        </Stack>
        <ConfirmationModel
          isOpen={showDeleteModel}
          title="Supprimer la recette"
          body={[
            "Vous êtes sur le point de supprimer",
            <strong> {current?.name}</strong>,
            ". Cela le supprimera définitivement de votre bibliothèque.",
          ]}
          maxWidth="sm"
          handleClose={toggleDeleteModel}
          onConfirm={() => {
            dispatch(deleteRecipe(current._id));
            toggleDeleteModel();
          }}
        />
        <ConfirmationModel
          showActions={false}
          isOpen={showInfoModel}
          title="Supprimer l’exercice"
          body="
                    Impossible de supprimer cet exercice car il fait
                    partie d’un programme en cours.
                "
          handleClose={toggleInfoModel}
        />
      </Container>
    </>
  );
};

export default NutritionPage;
