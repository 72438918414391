import { Box, Button, Grid, IconButton, Stack, styled } from "@mui/material";
import ConfirmationModel from "components/ConfirmationModel";
import Field from "components/Field";
import { required } from "components/Field/validate";
import TitleHeader from "components/TitleHeader";
import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  createClientNote,
  deleteClientNote,
  editClientNote,
  getClientNotes,
} from "store/actions/clients";
import { formatDate } from "utils/common";

const StyledBox = styled(Box)(() => ({
  border: "1px solid #E0E4EA",
  width: "100%",
  padding: "8px 12px 16px 16px",
  alignItems: "center",
  color: "#4F5668",
  fontSize: "14px",
  borderRadius: "8px",
  marginBottom: "8px",
  backgroundColor: "#ffffff",
  "&:last-child": {
    marginBottom: "0px",
  },
  ".note-detail": {
    backgroundColor: "#F5F5F5",
    padding: "16px",
    borderRadius: "8px",
  },
}));
const NoNoteBox = styled(Box)(({ theme }) => ({
  border: "1px solid #E0E4EA",
  borderRadius: "8px",
  padding: "10px",
  textAlign: "center",
  fontSize: "14px",
  color: "#4F5668",
  backgroundColor: "#ffffff",
}));
const ClientNotes = () => {
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();
  const clientNotes = useSelector((state) => state.clients.clientNotes);
  const [selectNote, setSelectNote] = useState();
  const formRef = useRef(null);
  useEffect(() => {
    if (clientNotes?.length === 0) {
      dispatch(getClientNotes(params?.id));
    }
  }, []);
  const toggleDeleteModel = () => {
    setShowDeleteModel(!showDeleteModel);
  };
  return (
    <>
      <Box py={3}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="space-between"
          gap={1}
          mb={4}
        >
          <TitleHeader title="Notes" fs={20} fw={600} />
          <Button
            color="primary"
            variant="contained"
            onClick={() => setShowNotesModal(true)}
          >
            + Ajouter une note
          </Button>
        </Stack>
        {clientNotes?.length === 0 && (
          <NoNoteBox>No note(s) available.</NoNoteBox>
        )}
        {clientNotes.map((item) => (
          <StyledBox key={item._id}>
            <Grid container>
              <Grid
                item
                sm={11}
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  fontSize: "12px",
                }}
              >
                {formatDate(item.created_on)}
              </Grid>
              <Grid
                item
                sm={1}
                sx={{ display: "flex", justifyContent: "right" }}
              >
                <IconButton
                  onClick={() => {
                    setShowNotesModal(true);
                    setSelectNote(item);
                  }}
                  color="inherit"
                  size="small"
                >
                  <Box
                    component="img"
                    src="/assets/logo/pencile_gray.svg"
                    alt="Edit Notes"
                  ></Box>
                </IconButton>
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={(e) => {
                    setShowDeleteModel(true);
                    setSelectNote(item);
                  }}
                >
                  <Box
                    component="img"
                    src="/assets/logo/trash_icon.svg"
                    alt="Exercise delete"
                  ></Box>
                </IconButton>
              </Grid>
            </Grid>
            <Box className="note-detail" mt={1}>
              {item.note}
            </Box>
          </StyledBox>
        ))}
      </Box>
      <ConfirmationModel
        isOpen={showNotesModal}
        confirmButtonColor="primary"
        back="Annuler"
        confirm="Ajouter"
        title="Ajouter une note"
        body={
          <>
            <Formik
              innerRef={formRef}
              enableReinitialize={true}
              initialValues={{ note: selectNote?.note || "" }}
            >
              <Field
                type="text"
                placeholder="Entrer une note.."
                name="note"
                notched={true}
                multiline={true}
                rows={10}
                validate={required}
              />
            </Formik>
          </>
        }
        maxWidth="sm"
        handleClose={() => setShowNotesModal(false)}
        onConfirm={() => {
          let data = {
            ...formRef.current?.values,
            user_id: params?.id,
          };
          if (selectNote?._id) {
            data = {
              ...selectNote,
              ...data,
            };
            dispatch(editClientNote(data));
          } else {
            dispatch(createClientNote(data));
          }
          setShowNotesModal(false);
          setSelectNote(null);
        }}
      />
      <ConfirmationModel
        isOpen={showDeleteModel}
        title="Supprimer une note"
        body={[
          "Vous êtes sur le point de supprimer la note du",
          <strong> {formatDate(selectNote?.created_on)}.</strong>,
        ]}
        maxWidth="sm"
        handleClose={toggleDeleteModel}
        onConfirm={() => {
          dispatch(deleteClientNote(selectNote?._id, toggleDeleteModel));
        }}
      />
    </>
  );
};
export default ClientNotes;
