// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: "600",
          borderRadius: "8px",
          textTransform: "capitalize",
        },
        containedPrimary: {
          background: "#0001FC",
          borderRadius: "8px",
          boxShadow: "none",
          padding: "8px 16px",
        },
        containedPrimary: {
          "&:hover": {
            boxShadow: "0px 2px 4px 0px #1F4FE852",
          },
        },
        containedSuccess: {
          borderRadius: "8px",
          "&:hover": {
            boxShadow: "0px 2px 4px 0px #119C8352",
          },
        },
        outlinedSecondary: {
          background: "#ffffff",
          borderRadius: "8px",
          boxShadow: "none",
          border: "1px solid #E0E4EA",
          padding: "8px 16px",
        },
        outlinedPrimary: {
          background: "#ffffff",
          borderRadius: "8px",
          boxShadow: "none",
          border: "1px solid #0001FC",
          padding: "7px 16px",
        },
        outlinedInfo: {
          background: "rgba(145, 158, 171, 0.24)",
          color: "rgba(145, 158, 171, 0.8)",
          boxShadow: "none",
        },
        containedError: {
          borderRadius: "8px",
          boxShadow: "none",
          padding: "8px 16px",
        },
      },
    },
  };
}
