import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Container,
  InputAdornment,
  Pagination,
  PaginationItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ConfirmationModel from "components/ConfirmationModel";
import Loader from "components/Loader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePagination,
  deleteCoachById,
  getCoaches,
  searchCoaches,
} from "store/actions/coaches";
import { setHeaderDetails } from "store/actions/header";
import InviteCoach from "../InviteCoach";
import CoachesListBody from "./CoachesListBody";
import CoachesListHeader from "./CoachesListHeader";

const CoachesList = () => {
  const dispatch = useDispatch();
  const coaches = useSelector((state) => state.coaches.coaches);
  const isLoadingCoaches = useSelector(
    (state) => state.coaches.isLoadingCoaches
  );
  const currentPage = useSelector((state) => state.coaches.currentPage);
  const [current, setCurrent] = useState(null);
  const [coachDetails, setCoachDetails] = useState(null);
  const [showDeleteModel, setDeleteModel] = useState(false);
  const [showInviteCoachModal, setShowInviteCoachModal] = useState(false);
  const start = (currentPage - 1) * 10;
  const end = start + 10;
  const currentPageItems = (coaches || []).slice(start, end).length;

  const toggleDeleteModel = (ele = "") => {
    setDeleteModel(!showDeleteModel);
    setCurrent(ele);
  };

  const toggleInviteCoachModal = (coach = null) => {
    setShowInviteCoachModal(!showInviteCoachModal);
    if (coach) {
      setCoachDetails(coach);
    } else {
      setCoachDetails(null);
    }
  };
  useEffect(() => {
    dispatch(getCoaches());
    const navDetail = {
      isBreadcrumns: false,
      title: "Entraîneurs",
    };

    dispatch(setHeaderDetails(navDetail));
  }, []);
  return (
    <>
      <Container
        maxWidth="xxl"
        className="header-box"
        sx={{ padding: "0 !important", marginBottom: "20px" }}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="space-between"
          gap={1}
        >
          <TextField
            placeholder="Rechercher un coach"
            name="Rechercher un coach"
            sx={{
              width: { sm: "100%", md: "414px" },
              ".MuiInputBase-root": {
                background: "#ffffff",
                borderRadius: "8px",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon color="#4F5668" />
                </InputAdornment>
              ),
            }}
            onChange={(e) => dispatch(searchCoaches(e.target.value))}
          />
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setShowInviteCoachModal(true);
            }}
          >
            {" "}
            + Inviter un entraîneur
          </Button>
        </Stack>
      </Container>
      <Container maxWidth="xxl">
        <Stack gap={1}>
          <Loader isLoading={isLoadingCoaches} />
          {!isLoadingCoaches && <CoachesListHeader />}
          {(coaches || []).length === 0 && !isLoadingCoaches && (
            <Box component="h5" textAlign="center">
              Vous n'avez pas encore de coachs.
            </Box>
          )}

          {!isLoadingCoaches && (
            <Box sx={{ minHeight: "544px" }}>
              {(coaches || []).slice(start, end).map((coach, index) => (
                <CoachesListBody
                  key={coach._id}
                  coach={coach}
                  toggleDeleteModel={toggleDeleteModel}
                  toggleInviteCoachModal={toggleInviteCoachModal}
                />
              ))}
            </Box>
          )}
          {!isLoadingCoaches && (coaches || []).length > 10 && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography color="#000000" fontSize="12px" fontWeight="500">
                Affichage de {start + 1} à {start + currentPageItems} sur{" "}
                {coaches.length} résultats
              </Typography>
              <Pagination
                page={currentPage}
                onChange={(page, value) => dispatch(changePagination(value))}
                count={Math.ceil(coaches.length / 10)}
                variant="outlined"
                shape="rounded"
                color="secondary"
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
              />
            </Stack>
          )}
        </Stack>
        <InviteCoach
          title={
            coachDetails ? "Détails de l’entraîneur" : "Inviter un entraîneur"
          }
          showModal={showInviteCoachModal}
          toggleInviteCoachModal={toggleInviteCoachModal}
          onDeleteHandler={toggleDeleteModel}
          coachDetails={coachDetails}
        />
        <ConfirmationModel
          isOpen={showDeleteModel}
          title="Supprimer Coach"
          body={[
            "Vous allez supprimer ce coach. Cette action est irréversible. Pour continuer, cliquez sur Supprimer.",
          ]}
          handleClose={toggleDeleteModel}
          maxWidth="sm"
          onConfirm={() => {
            dispatch(deleteCoachById(current._id, toggleDeleteModel)).then(() =>
              toggleDeleteModel()
            );
          }}
        />
      </Container>
    </>
  );
};

export default CoachesList;
