import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  InputAdornment,
  ListItemAvatar,
  TextField,
} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/styles";
import { connect } from "formik";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getExercises, searchExercise } from "store/actions/exercise";
import { addExercise } from "store/actions/program";
import { THUMBNAIL_PLACEHOLDER } from "utils/constants";
import ConfirmationModel from "../../ConfirmationModel";

const Demo = styled("div")(({ theme }) => ({
  overflow: "auto",
  maxHeight: "350px",
  minHeight: "350px",
  border: "1px solid #CCCCCC",
  borderRadius: "12px",
  backgroundColor: theme.palette.background.paper,
  "&::-webkit-scrollbar": {
    width: "8px",
    backgroundColor: "#F5F5F5",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: " inset 0 0 8px rgba(0,0,0,0.3)",
    borderRadius: "12px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#222222",
    borderRadius: "12px",
  },
}));

const AddExerciseModel = (props) => {
  const dispatch = useDispatch();
  const { setFieldValue, values } = props.formik;
  const { current } = props;
  const programData = useSelector((state) => state.exercise.exercises);
  const activeWeek = useSelector((state) => state.programs.activeWeek);
  const activeDay = useSelector((state) => state.programs.activeDay);

  useEffect(() => {
    dispatch(getExercises());
  }, []);

  const activeElement = values["week"]
    ? values["week"][activeWeek]
      ? values["week"][activeWeek][activeDay]
      : {}
    : {};
  let exercisesList = activeElement ? activeElement["exercise_groups"] : [];
  const [selectedExercises, setSelectedExercises] = useState([]);

  const isExersiceAdded = (id) => {
    return _.some(selectedExercises, {
      exercise_id: id,
    });
  };

  const selectExercise = (extercise) => {
    const data = _.pick(extercise, ["thumbnail", "description", "name", "_id"]);
    const newElement = {
      exercise_id: extercise["_id"],
      program: data,
      sets: [
        {
          unit1: {
            name: "kg",
            value: "",
          },
          unit2: {
            name: "rep",
            value: "",
          },
          rest: "",
        },
      ],
    };
    setSelectedExercises([...selectedExercises, newElement]);
  };

  const removeSelectedExercise = (id) => {
    const extercises = _.remove(selectedExercises, (n) => n.exercise_id !== id);
    setSelectedExercises(extercises);
  };

  const addExercisesToProgram = () => {
    dispatch(
      addExercise(
        selectedExercises,
        current,
        setFieldValue,
        exercisesList[current].exercises
      )
    );
    props.toggle();
  };

  return (
    <ConfirmationModel
      confirmButtonColor="primary"
      confirm="Ajouter"
      back="Fermer"
      isOpen={props.isOpen || false}
      title={props.title ? props.title : "Ajouter un exercice"}
      maxWidth="md"
      disableConfirmButton={selectedExercises.length === 0}
      body={
        <>
          <TextField
            placeholder="Search exercises"
            variant="outlined"
            fullWidth={true}
            size="small"
            onChange={(e) => dispatch(searchExercise(e.target.value))}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
          />

          {(programData || []).length === 0 ? (
            <span>Not Available</span>
          ) : (
            <Box mt={3}>
              <Demo>
                <List>
                  {(programData || []).map((program, index) => {
                    return (
                      <ListItem
                        key={index}
                        sx={{
                          paddingRight: "70px",
                          backgroundColor: isExersiceAdded(program._id)
                            ? "#E4F2E5"
                            : "none",
                        }}
                        secondaryAction={
                          <>
                            {isExersiceAdded(program._id) ? (
                              <Button
                                color="success"
                                startIcon={<CheckCircleIcon />}
                                size="small"
                                variant="outlined"
                                sx={{ backgroundColor: "#ffffff" }}
                                onClick={() => {
                                  removeSelectedExercise(program._id);
                                }}
                              >
                                Select
                              </Button>
                            ) : (
                              <Button
                                color="success"
                                startIcon={<AddIcon />}
                                size="small"
                                variant="contained"
                                onClick={() => {
                                  selectExercise(program);
                                }}
                              >
                                Select
                              </Button>
                            )}
                          </>
                        }
                      >
                        <ListItemAvatar sx={{ display: "flex" }}>
                          <img
                            src={program.thumbnail}
                            height="40px"
                            width="40px"
                            alt="gym.jpg"
                            onError={(event) => {
                              event.target.src = THUMBNAIL_PLACEHOLDER;
                              event.onerror = null;
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText primary={program.name} />
                      </ListItem>
                    );
                  })}
                </List>
              </Demo>
            </Box>
          )}
        </>
      }
      handleClose={props.toggle}
      onConfirm={() => {
        addExercisesToProgram();
      }}
    />
  );
};

export default connect(AddExerciseModel);
