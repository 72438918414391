import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Container,
  InputAdornment,
  Pagination,
  PaginationItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ConfirmationModel from "components/ConfirmationModel";
import FilterMenu from "components/FilterMenu";
import Loader from "components/Loader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setHeaderDetails } from "store/actions/header";
import {
  changePagination,
  cleanAllProgramData,
  deleteProgramById,
  getInitialProgram,
  getProgramLists,
  searchPrograms,
  setFormData,
} from "store/actions/program";
import useDocumentTitle from "utils/hooks/useDocumentTitle";
import { programStatuses } from "utils/programs.utils";
import ProgrammItem from "./ProgramItem";
import ProgramTitles from "./ProgramTitles";

const ProgrammsPage = () => {
  useDocumentTitle("Mes Programmes Fitness", true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const programms = useSelector((state) => state.programs.programms);
  const currentPage = useSelector((state) => state.programs.currentPage);
  const isLoadingProgramms = useSelector(
    (state) => state.programs.isLoadingProgramms
  );
  const [showDeleteModel, setDeleteModel] = useState(false);
  const [showInfoModel, setInfoModel] = useState(false);
  const [current, setCurrent] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    dispatch(getProgramLists());
    const navDetail = {
      isBreadcrumns: false,
      title: "Programmes d’entraînements",
    };

    dispatch(setHeaderDetails(navDetail));
  }, []);

  const toggleDeleteModel = (ele = "") => {
    setDeleteModel(!showDeleteModel);
    setCurrent(ele);
  };

  const toggleInfoModel = () => {
    setInfoModel(!showInfoModel);
  };
  const props = { toggleDeleteModel, toggleInfoModel };
  const start = (currentPage - 1) * 10;
  const end = start + 10;
  const currentPageItems = (programms || []).slice(start, end).length;

  const getStatusFilterValues = (values) => {
    setFilters(values);
    dispatch(searchPrograms(searchQuery, values));
  };

  return (
    <>
      <Container
        maxWidth="xxl"
        className="header-box"
        sx={{ padding: "0 !important", marginBottom: "20px" }}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="space-between"
          gap={1}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            justifyContent="space-between"
            gap={1}
          >
            <TextField
              placeholder="Rechercher un programme"
              name="search programme"
              sx={{
                width: { sm: "100%", md: "414px" },
                ".MuiInputBase-root": {
                  background: "#ffffff",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                dispatch(searchPrograms(e.target.value, filters));
              }}
            />
            <FilterMenu
              menuTitle="Statut"
              getSelectedValues={getStatusFilterValues}
              menuList={programStatuses}
            />
          </Stack>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              dispatch(cleanAllProgramData());
              dispatch(getInitialProgram());
              dispatch(setFormData());
              navigate("/fitness/programs/create");
            }}
          >
            {" "}
            + Créer un programme d’entraînement
          </Button>
        </Stack>
      </Container>
      <Container maxWidth="xxl">
        <Stack gap={3}>
          <Stack gap={1}>
            <Loader isLoading={isLoadingProgramms} />
            {!isLoadingProgramms && <ProgramTitles />}
            {(programms || []).length === 0 && !isLoadingProgramms && (
              <Box component="h5" textAlign="center">
                Vous n'avez pas encore de programmes.
              </Box>
            )}
            {!isLoadingProgramms && (
              <Box sx={{ minHeight: "608px" }}>
                {(programms || []).slice(start, end).map((program, index) => (
                  <ProgrammItem key={index} program={program} {...props} />
                ))}
              </Box>
            )}
          </Stack>
          {!isLoadingProgramms && (programms || []).length > 10 && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography color="#000000" fontSize="12px" fontWeight="500">
                Affichage de {start + 1} à {start + currentPageItems} sur{" "}
                {programms.length} résultats
              </Typography>
              <Pagination
                page={currentPage}
                onChange={(page, value) => dispatch(changePagination(value))}
                count={Math.ceil(programms.length / 10)}
                variant="outlined"
                shape="rounded"
                color="secondary"
                renderItem={(item) => (
                  <PaginationItem
                    slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                    {...item}
                  />
                )}
              />
            </Stack>
          )}
        </Stack>
        <ConfirmationModel
          isOpen={showDeleteModel}
          title="Supprimer le programme"
          body={[
            "Vous êtes sur le point de supprimer",
            <strong> {current?.name}</strong>,
            ". Cela le supprimera définitivement de votre bibliothèque.",
          ]}
          maxWidth="sm"
          handleClose={toggleDeleteModel}
          onConfirm={() => {
            dispatch(deleteProgramById(current._id, toggleInfoModel)).then(() =>
              toggleDeleteModel()
            );
          }}
        />
        <ConfirmationModel
          showActions={false}
          isOpen={showInfoModel}
          title="Supprimer l’exercice"
          body="
                    Impossible de supprimer cet exercice car il fait
                    partie d’un programme en cours.
                "
          handleClose={toggleInfoModel}
        />
      </Container>
    </>
  );
};

export default ProgrammsPage;
