import { Alert, Grid, IconButton, Link, styled } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomIcon from "components/CustomIcon";
import Status from "components/Status";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  cleanAllNutritionData,
  cloneNutritionProgram,
} from "store/actions/nutritions";
import { formatDate } from "utils/common";
import { THUMBNAIL_PLACEHOLDER_GRAY } from "utils/constants";

const StyledAlert = styled(Alert)(() => ({
  cursor: "pointer",
  border: "1px solid #E0E4EA",
  ".MuiAlert-icon": {
    display: "none",
  },
  ".MuiAlert-action": {
    alignItems: "center",
  },
  ".MuiAlert-message": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: "#4F5668",
    fontSize: "14px",
    padding: 0,
  },
}));

function checkAndAddCopy(str) {
  if (str.includes("copy")) {
    const regex = /copy\s*(\d*)$/;
    const match = str.match(regex);
    let count = 1;
    if (match && match[1]) {
      count = parseInt(match[1], 10);
    }
    str = str.replace(regex, "");

    return str.trim() + " copy " + (count + 1);
  } else {
    return str.trim() + " copy";
  }
}

const useStyle = makeStyles(() => ({
  img: {
    height: "40px",
    width: "40px",
    borderRadius: "8px",
  },
}));

const NutritionItem = (props) => {
  const { toggleDeleteModel, nutrition } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyle();
  const fullName = (fName, lName) => {
    if (fName || lName) {
      return `${fName} ${lName}`;
    } else {
      return "-";
    }
  };
  return (
    <StyledAlert
      variant="outlined"
      severity="info"
      onClick={() => {
        dispatch(cleanAllNutritionData());
        navigate(`/nutrition/programs/${nutrition["_id"]}`);
      }}
    >
      <Grid container>
        <Grid
          item
          sm={4}
          sx={{ display: "flex", gap: "10px", alignItems: "center" }}
        >
          <img
            src={nutrition.thumbnail}
            alt="profile"
            className={classes.img}
            onError={(event) => {
              event.target.src = THUMBNAIL_PLACEHOLDER_GRAY;
              event.onerror = null;
            }}
          />
          <Link
            underline="hover"
            color="#4F5668"
            sx={{ cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(cleanAllNutritionData());
              navigate(`/nutrition/programs/${nutrition["_id"]}`);
            }}
          >
            {nutrition.name}
          </Link>
        </Grid>
        <Grid item sm={2} sx={{ display: "flex", alignItems: "center" }}>
          <Link
            underline="hover"
            color="#4F5668"
            sx={{ cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              if (nutrition.user_id) {
                navigate(`/clients/${nutrition.user_id}`);
              }
            }}
          >
            {fullName(nutrition.first_name, nutrition.last_name)}
          </Link>
        </Grid>
        <Grid item sm={3} sx={{ display: "flex", alignItems: "center" }}>
          {`${formatDate(nutrition.start_date) || ""} - ${
            formatDate(nutrition.end_date) || ""
          }`}
        </Grid>
        <Grid item sm={1} sx={{ display: "flex", alignItems: "center" }}>
          <Status type={nutrition.status} label={nutrition.status} />
        </Grid>
        <Grid item sm={2} sx={{ display: "flex", justifyContent: "right" }}>
          <IconButton
            color="inherit"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(cleanAllNutritionData());
              navigate(`/nutrition/programs/${nutrition["_id"]}`);
            }}
          >
            <CustomIcon
              iconSrc="/assets/logo/detail_icon.svg"
              iconHoverSrc="/assets/logo/detail_icon_hover.svg"
            />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              // nutrition.name = checkAndAddCopy(nutrition.name);
              dispatch(cloneNutritionProgram(nutrition._id));
            }}
          >
            <CustomIcon
              iconSrc="/assets/logo/copy.svg"
              iconHoverSrc="/assets/logo/copy_hover.svg"
            />
          </IconButton>
          <IconButton
            color="info.main"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              toggleDeleteModel(nutrition);
            }}
          >
            <CustomIcon
              iconSrc="/assets/logo/trash_icon.svg"
              iconHoverSrc="/assets/logo/trash_icon_hover.svg"
            />
          </IconButton>
        </Grid>
      </Grid>
    </StyledAlert>
  );
};

export default NutritionItem;
