import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CreateProgramDays from "components/CreateProgramPage";
import ExerciseDetailModel from "components/CreateProgramPage/SeanceExercise/ExerciseDetailModel";
import SessionForm from "components/CreateProgramPage/SessionForm";
import { FormContext } from "components/FormContext";
import ProgramSaveAsTemplateModal from "components/ProgramSaveAsTemplateModal";
import SendProgramModal from "components/SendProgramModal";
import TitleHeader from "components/TitleHeader";
import { addDays } from "date-fns";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getExercises } from "store/actions/exercise";
import { setHeaderDetails } from "store/actions/header";
import {
  addWeek,
  duplicateThisWeek,
  getCurrentProgram,
  removeWeek,
  setActiveWeek,
  setFormData,
  setProgramData,
} from "store/actions/program";
import { setPrompt } from "store/actions/prompt";
import { ATTENTION_MESSAGE } from "utils/constants";
import Loader from "../../components/Loader";

const StyledSelect = styled(Select)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 600,
  color: "#4F5668",
  borderRadius: "8px",
  backgroundColor: "#ffffff",
  padding: "1px 12px",
  ".MuiOutlinedInput-notchedOutline": {
    border: "1px solid #E0E4EA",
  },
}));
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 600,
  color: "#4F5668",
}));

const StyledSaveSelect = styled(Select)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 600,
  color: "#119C83",
  borderRadius: "8px",
  backgroundColor: "#E4F2E5",
  padding: "0px 12px",
  verticalAlign: "middle",
  ".MuiOutlinedInput-notchedOutline": {
    border: "1px solid #119C83",
  },
  ".MuiSelect-select": {
    color: "#119C83",
  },
}));
const StyledSaveMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: "14px",
  color: "#111111",
  paddingTop: "10px",
  paddingBottom: "10px",
  "&:hover": {
    backgroundColor: "#E4F2E5",
  },
}));

const CreateProgramPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { formikRef, handleSubmit } = useContext(FormContext);
  const navigate = useNavigate();

  const totalWeek = useSelector((state) => state.programs.totalWeek);
  const activeDay = useSelector((state) => state.programs.activeDay);
  const activeWeek = useSelector((state) => state.programs.activeWeek);
  const formData = useSelector((state) => state.programs.formData);
  const weeks = useSelector((state) => state.programs.programItem.weeks);
  const programBasicInfo = useSelector((state) => state.programs.programData);
  const [showSaveTemplateModal, setShowSaveTemplateModal] = useState(false);
  const [showSendProgramModal, setShowSendProgramModal] = useState(false);
  const isLoadingProgramms = useSelector(
    (state) => state.programs.isLoadingProgramms
  );
  let isDeleteSessionDisabled = true;
  if (weeks[activeWeek + 1] && weeks[activeWeek + 1][activeDay + 1]?.session) {
    isDeleteSessionDisabled = false;
  }

  useEffect(() => {
    dispatch(getExercises());
    if (id && formikRef) {
      dispatch(getCurrentProgram(id, formikRef));
    }
    const navDetail = {
      isBreadcrumns: true,
      title: "",
      breadcrumsList: [
        {
          title: "Programmes d’entraînements",
          link: "/fitness/programs",
          command: () => {
            dispatch(
              setPrompt({
                isOpen: true,
                message: ATTENTION_MESSAGE,
                primaryBtnHandler: () => {
                  navigate("/fitness/programs");
                },
                btnAlign: "right",
                primaryBtnColor: "error",
                secondaryBtnText: "Annuler",
              })
            );
          },
        },
        {
          title: "Étape 1",
          link: id ? `/fitness/programs/${id}` : "/fitness/programs/create",
          command: () => {
            dispatch(
              setPrompt({
                isOpen: true,
                message:
                  "Vous êtes sur le point de retour à l’étape 1 sans enregistrer, ce qui entraînera la perte de vos modifications. Êtes-vous sûr de vouloir continuer cette action ?",
                primaryBtnHandler: () => {
                  navigate(
                    id ? `/fitness/programs/${id}` : "/fitness/programs/create"
                  );
                },
                btnAlign: "right",
                primaryBtnColor: "error",
                secondaryBtnText: "Annuler",
              })
            );
          },
        },
        {
          title: "Étape 2",
          link: "",
        },
      ],
    };
    dispatch(setHeaderDetails(navDetail));
  }, []);
  useEffect(() => {
    updateEndDate(totalWeek);
  }, [totalWeek, programBasicInfo?.start_date]);

  const handlePrevPaginate = () => {
    if (activeWeek !== 0) {
      dispatch(setActiveWeek(activeWeek - 1));
    }
  };

  const handleNextPaginate = () => {
    if (activeWeek !== totalWeek - 1) {
      dispatch(setActiveWeek(activeWeek + 1));
    }
  };

  const handleFitnessSaveActions = (event) => {
    if (event.target.value === "SAVE_AS_TEMPLATE") {
      setShowSaveTemplateModal(true);
    } else if (event.target.value === "SAVE_AS_DRAFT") {
      formikRef.current?.setFieldValue("status", "Brouillon");
      dispatch(
        setProgramData({
          ...programBasicInfo,
          status: "Brouillon",
          user_id: "",
        })
      );
      handleSubmit();
    }
  };

  const handleChange = (event) => {
    if (event.target.value === "DUPLICATE_WEEK") {
      dispatch(duplicateThisWeek(formikRef, activeWeek));
    } else if (event.target.value === "ADD_WEEK") {
      dispatch(addWeek(formikRef, activeWeek));
    } else if (event.target.value === "REMOVE_WEEK") {
      dispatch(removeWeek(formikRef, activeWeek));
    }
  };

  const updateEndDate = (weeks) => {
    const endDate = dayjs(
      addDays(new Date(programBasicInfo?.start_date), weeks * 7)
    );
    dispatch(
      setProgramData({
        ...programBasicInfo,
        end_date: endDate,
        program_weeks: weeks,
      })
    );
    dispatch(
      setFormData({
        ...formData,
        end_date: endDate,
        program_weeks: weeks,
      })
    );
  };

  const updateStartDate = (date) => {
    dispatch(
      setProgramData({
        ...programBasicInfo,
        start_date: date,
      })
    );
    dispatch(
      setFormData({
        ...formData,
        start_date: date,
      })
    );
  };

  return (
    <>
      <Loader isLoading={isLoadingProgramms} />
      <Container
        maxWidth="xxl"
        sx={{
          display: isLoadingProgramms ? "none" : "",
          paddingTop: "0px !important",
        }}
        className="no-bg"
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="space-between"
          gap={1}
        >
          <TitleHeader title={programBasicInfo?.name} />
          <Box>
            <StyledSaveSelect
              id="save"
              value={""}
              sx={{
                marginRight: "10px",
                display:
                  id &&
                  (programBasicInfo?.status === "Actif" ||
                    programBasicInfo?.status === "Modèle")
                    ? "none"
                    : "inline-block",
              }}
              displayEmpty
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return "Sauvegarder";
                }
              }}
              onChange={handleFitnessSaveActions}
            >
              <StyledSaveMenuItem value="SAVE_AS_TEMPLATE">
                Sauvegarder
              </StyledSaveMenuItem>
              <StyledSaveMenuItem value="SAVE_AS_DRAFT">
                En tant que brouillon
              </StyledSaveMenuItem>
            </StyledSaveSelect>
            {id && programBasicInfo?.status === "Modèle" && (
              <Button
                type="submit"
                color="success"
                variant="outlined"
                sx={{ marginRight: "10px" }}
                onClick={() => setShowSaveTemplateModal(true)}
              >
                Sauvegarder
              </Button>
            )}
            <Button
              type="submit"
              color="success"
              variant="contained"
              onClick={() => {
                if (id && programBasicInfo?.status === "Actif") {
                  handleSubmit();
                } else {
                  setShowSendProgramModal(true);
                }
              }}
            >
              {id && programBasicInfo?.status === "Actif"
                ? "Sauvergarder les modifications"
                : "Envoyer"}
            </Button>
          </Box>
        </Stack>
        <Divider sx={{ margin: "20px 0" }} />
        <Box pb={2}>
          <Stack
            direction="row"
            alignItems="center"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center" gap={2}>
              <IconButton onClick={handlePrevPaginate}>
                <img src="/assets/logo/previous.svg" alt="previous" />
              </IconButton>
              <Typography fontWeight="700" fontFamily="Poppins" fontSize="24px">
                Semaine {activeWeek + 1} sur {totalWeek}
              </Typography>
              <IconButton onClick={handleNextPaginate}>
                <img src="/assets/logo/next.svg" alt="previous" />
              </IconButton>
            </Stack>
            <Stack direction="row" alignItems="center">
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="fr"
              >
                <DatePicker
                  label="Date de début"
                  onChange={(newValue) => updateStartDate(newValue)}
                  sx={{
                    backgroundColor: "#ffffff",
                    width: "155px",
                    marginRight: "15px",
                  }}
                  format="DD MMM YYYY"
                  value={programBasicInfo?.start_date}
                />
              </LocalizationProvider>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="fr"
              >
                <DatePicker
                  label="Date de fin"
                  disabled
                  format="DD MMM YYYY"
                  sx={{ width: "155px", marginRight: "15px" }}
                  minDate={dayjs(programBasicInfo?.start_date)}
                  value={programBasicInfo?.end_date}
                />
              </LocalizationProvider>
              <FormControl size="small">
                <StyledSelect
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={""}
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return "Gérer";
                    }
                  }}
                  onChange={handleChange}
                >
                  <StyledMenuItem value="DUPLICATE_WEEK">
                    Dupliquer la semaine
                  </StyledMenuItem>
                  <StyledMenuItem value="ADD_WEEK">
                    Ajouter une semaine
                  </StyledMenuItem>
                  <StyledMenuItem value="REMOVE_WEEK">
                    Supprimer la semaine
                  </StyledMenuItem>
                </StyledSelect>
              </FormControl>
            </Stack>
          </Stack>
          <CreateProgramDays />
        </Box>
        <Box>
          <SessionForm isDeleteSessionDisabled={isDeleteSessionDisabled} />
        </Box>
        <ExerciseDetailModel />
      </Container>
      <ProgramSaveAsTemplateModal
        isFitnessProgram={true}
        showSaveTemplateModal={showSaveTemplateModal}
        setShowSaveTemplateModal={setShowSaveTemplateModal}
        handleSubmit={() => {
          formikRef.current?.setFieldValue("status", "Modèle");
          dispatch(
            setProgramData({
              ...programBasicInfo,
              user_id: "",
              status: "Modèle",
            })
          );
          handleSubmit();
        }}
      />
      <SendProgramModal
        isFitnessProgram={true}
        showSendProgramModal={showSendProgramModal}
        setShowSendProgramModal={setShowSendProgramModal}
        handleDraftSubmit={() => {
          formikRef.current?.setFieldValue("status", "Brouillon");
          dispatch(
            setProgramData({
              ...programBasicInfo,
              status: "Brouillon",
              user_id: "",
            })
          );
          setShowSendProgramModal(false);
          handleSubmit();
        }}
      />
    </>
  );
};

export default CreateProgramPage;
