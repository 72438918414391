import axios from "axios";
import { filterRecords } from "utils/common";
export const GET_SESSIONS_DATA = "get_sessions_data";
export const getSessions = () => {
  return (dispatch) => {
    dispatch(loadingSessions(true));
    axios
      .get("/api/coach/sessions?independent_session=true")
      .then((res) => {
        dispatch({
          type: GET_SESSIONS_DATA,
          payload: res.data.data,
        });
        dispatch(loadingSessions(false));
      })
      .catch(() => {
        dispatch(loadingSessions(false));
      });
  };
};

export const PAGINATION_SESSIONS_DATA = "pagination_sessions_data";
export const changePagination = (response = 1) => {
  return (dispatch) => {
    dispatch({
      type: PAGINATION_SESSIONS_DATA,
      payload: response,
    });
  };
};

export const SEARCH_SESSIONS_DATA = "search_sessions_data";
export const searchSessions = (search = "", filters = []) => {
  return (dispatch, getState) => {
    let filteredItem = getState().sessions.searchedSessions || [];
    filteredItem = filterRecords(filteredItem, search, filters, "status");
    dispatch({
      type: SEARCH_SESSIONS_DATA,
      payload: filteredItem,
    });
  };
};

export const LOADING_SESSIONS_DATA = "loading_sessions_data";
export const loadingSessions = (response = false) => {
  return (dispatch) => {
    dispatch({
      type: LOADING_SESSIONS_DATA,
      payload: response,
    });
  };
};

export const deleteSessionById = (sessionId = "", toggleDeleteModel) => {
  return (dispatch, getState) => {
    axios
      .delete(`/api/coach/sessions/${sessionId}`)
      .then(() => {
        dispatch(getSessions());
        toggleDeleteModel();
      })
      .catch((err) => {
        toggleDeleteModel();
      });
  };
};
