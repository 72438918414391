import { Alert, Grid, IconButton, Link, styled } from "@mui/material";
import CustomIcon from "components/CustomIcon";
import Status from "components/Status";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cleanAllProgramData, getInitialProgram } from "store/actions/program";

const StyledAlert = styled(Alert)(() => ({
  cursor: "pointer",
  border: "1px solid #E0E4EA",
  padding: "12px 16px",
  ".MuiAlert-icon": {
    display: "none",
  },
  ".MuiAlert-action": {
    alignItems: "center",
  },
  ".MuiAlert-message": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: "#4F5668",
    fontSize: "14px",
    padding: 0,
  },
}));

const SessionListBody = ({ toggleDeleteModel, session }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { type } = useSelector((state) => state.user.userDetails);
  const fullName = (fName, lName) => {
    if (fName || lName) {
      return `${fName} ${lName}`;
    } else {
      return "-";
    }
  };
  return (
    <StyledAlert
      variant="outlined"
      severity="info"
      onClick={() => {
        dispatch(cleanAllProgramData());
        dispatch(getInitialProgram());
        navigate(`/fitness/sessions/${session._id}`);
      }}
    >
      <Grid container>
        <Grid
          item
          sm={4}
          sx={{ display: "flex", gap: "10px", alignItems: "center" }}
        >
          <Link
            underline="hover"
            color="#4F5668"
            sx={{ cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(cleanAllProgramData());
              dispatch(getInitialProgram());
              navigate(`/fitness/sessions/${session._id}`);
            }}
          >
            {session.name}
          </Link>
        </Grid>
        <Grid item sm={4} sx={{ display: "flex", alignItems: "center" }}>
          <Link
            underline="hover"
            color="#4F5668"
            sx={{ cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              if (session.user_id) {
                navigate(`/clients/${session.user_id}`);
              }
            }}
          >
            {fullName(session.first_name, session.last_name)}
          </Link>
        </Grid>
        <Grid item sm={2} sx={{ display: "flex", alignItems: "center" }}>
          <Status type={session.status} label={session.status}></Status>
        </Grid>
        <Grid item sm={2} sx={{ textAlign: "right" }}>
          <IconButton
            color="inherit"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(cleanAllProgramData());
              dispatch(getInitialProgram());
              navigate(`/fitness/sessions/${session._id}`);
            }}
          >
            <CustomIcon
              iconSrc="/assets/logo/detail_icon.svg"
              iconHoverSrc="/assets/logo/detail_icon_hover.svg"
            />
          </IconButton>
          <IconButton
            color="info.main"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              toggleDeleteModel(session);
            }}
          >
            <CustomIcon
              iconSrc="/assets/logo/trash_icon.svg"
              iconHoverSrc="/assets/logo/trash_icon_hover.svg"
            />
          </IconButton>
        </Grid>
      </Grid>
    </StyledAlert>
  );
};

export default SessionListBody;
